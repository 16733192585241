// variables loaded before loading vendor variables, used to prepare vendor variables like bootstrap

// font
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;700&display=swap');

$font-family-outfit: 'Outfit', sans-serif;
$font-family-base: $font-family-outfit;

$font-awesome: "Font Awesome 5 Pro";
// build options
$use-sticky-header: true;
$use-html-fontsize-reset: true;

// colors
$primary: #C49D66;
$secondary: #CA7B6A;
$tertiary: #725149;
$quaternary: #E6E9E3;
$grey-goose: #CED4C8;
$rose-fog: #E4BDB5;
$rodeo-dust: #DDAB99;
$lunar-green: #364F31;
$heather: #B1C2CA;
$pastel-brown: #856555;
$dust: #B1966B;
$petite-orchid: #d49588;

// matrix-colors
$matrix-color-primary: #C49D66;
$matrix-color-secondary: #CA7B6A;
$matrix-color-tertiary: #725149;
$matrix-color-quaternary: #E6E9E3;

$link-color: black !default;
$link-color--hover: $secondary !default;

// typography
$font-weight-light: 100 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-base: normal !default;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.5 !default;
$h3-font-size: $font-size-base * 1.85 !default;
$h4-font-size: $font-size-base * 1.875 !default;
$h5-font-size: $font-size-base * 1.5 !default;
$h6-font-size: $font-size-base * 1.125 !default;

// Components
$line-height-base: 1.5 !default;
//

// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-radius: 12px !default;

// Buttons + Forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.9375rem !default;
$input-btn-padding-x: 3rem !default;
$input-btn-font-family: $font-family-base !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-border-width: $border-width !default;


// Buttons
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-border-width: $input-btn-border-width !default;
$btn-font-weight: $font-weight-medium !default;

$btn-border-radius: $border-radius !default;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
box-shadow 0.15s ease-in-out !default;


// carousel
$carousel-min_height: 500px !default;
$carousel-min_height--lg: 75vh !default;
body {
	&.default {
		overflow-x: hidden;
	}
}
