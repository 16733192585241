/* 	content-section
    ========================================================================== */
	.content-section {
		.intro,
		.outro {
			max-width: 760px;
			.container-holder {
				text-align: center;
			}
		}
		.intro {
			.container-holder {
				margin-bottom: 30px;
				p:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		.outro {
			.container-holder {
				margin-top: 30px;
				p:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

/* 	impression-section
    ========================================================================== */
	.impression-section {
		.column.one {
			@include media-breakpoint-up(xl) {
				@include make-col(4);
			}
		}
		.column.two {
			@include media-breakpoint-up(xl) {
				@include make-col(8);
			}
		}
	}


/* 	booking-section
    ========================================================================== */
	.booking-section {
		@extend .bg-light;
		h2 {
			text-align: center;
		} 
	}