// parent behaviour
.header {
  .logo {
    img {
      max-height: #{$header-logo-height};
    }

    @include media-breakpoint-up(xl) {
      min-width: auto;

      img {
        max-height: $header-logo-height--lg;
      }
    }
  }
}

@if $use-sticky-header {
  .header.sticky {
    .logo img {
      max-height: #{$header-logo-height};
    }
  }
}
