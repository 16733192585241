@if $use-sticky-header {
  // body padding for sticky header
  @include media-breakpoint-up(xl) {
    & { padding-top: #{$header-logo-height--lg + $header-logo-margin + 10px}; }
    &.sticky { padding-top: #{$header-logo-height + $header-logo-margin + 10px}; }
  }

  .header {
    // sticky header absolute positioning
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }

    @include media-breakpoint-up(xl) {
      &.sticky {
        position: fixed;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

        // resize column height in sticky mode
        .column.default {
          height: #{$header-logo-height + $header-logo-margin + 10px};
        }
      }
    }
  }
}
