.mini-sab {
	position: relative;
	@media(max-width: 991px) {
		background: #C49D66;
	}

	.mzab-wrapper {
		.container-holder {
			margin-left: 0;
			margin-right: 0;
			@media(max-width: 991px) {
				margin-bottom: 0;
				justify-content: center;
				padding-bottom: 15px;
			}

			.column {
				margin-bottom: 0 !important;

				&.one {
					display: flex;
					align-items: center;
					flex-flow: row wrap;
					padding-left: 25px;
					flex: 0 0 100%;
					max-width: 1400px;
					background-color: transparent;
					background: url(/images/curve.png) no-repeat left top;
					background-size: 100% auto;
					height: 170px;
					margin-top: -169px;
					position: relative;
					z-index: +1;
					@media(max-width: 991px) {
						max-width: 100%;
						background-position: left center;
						background-size: 100% 100%;
					}

					.logo {
						max-width: 350px;
						transition: 200ms;
						@media(max-width: 991px) {
							margin-top: 35px;
							max-width: 340px;
						}
					}
				}

				&.two {
					flex: 0 0 auto !important;
					width: 100vw;
					background: #CED4C8;
					max-width: 380px;
					padding: 15px 75px 0 25px !important;
					border-radius: 12px;

					@media(min-width: 992px) {
						position: absolute;
						right: 3vw;
						bottom: 15px;
						z-index: +1;
					}
					@media(max-width: 991px) {
						padding-left: 15px !important;
						padding-right: 15px !important;
					}

					tommy-search-and-book-mini {
						.tommy-zeb-left.ng-scope {
							> * {
								max-width: 100%;
							}

							tommy-confirm-update {
								button {
									@media(max-width: 991px) {
										padding: 0 0 15px;
									}

									&:after {
										@media(max-width: 991px) {
											margin-left: auto;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

&.home:not(.sticky) {
	.mini-sab {
		.logo {
			@media(min-width: 992px) {
				visibility: hidden;
				opacity: 0;
			}
		}
	}
}

&.accommodation-detail {
	.mini-sab {
		.mzab-wrapper {
			.container-holder {
				.column {
					&.two {
						display: none;
					}
				}
			}
		}
	}
} 