.btn {
    height: 59px;
    padding: ($input-btn-padding-y) ($input-btn-padding-x) ($input-btn-padding-y) (2rem);
    @include flexbox;
    @include justify-content($value: center);
    @include align-items($value: center);
    width: max-content;
    position: relative;

    &::after {
        content: "\f061";
        position: absolute;
        right: 20px;
        top: 18px;
        font-family: $font-awesome;
        font-size: $h6-font-size;
        font-weight: $font-weight-bold;
    }
    /* btn-primary */
    &.btn-primary {
        background: $primary;
        border-color: $primary;
        color: $black;
        &:hover {
            background: transparent;
            border-color: $black;
            color: $black;
        }
    }

    /* btn-outline-primary */
    &.btn-outline-primary {
        background-color: transparent;
        border-color: $primary;
        color: $primary;
        &:hover {
            background: transparent;
            border-color: $black;
            color: $black;
        }
    }

    /* btn-secondary */
    &.btn-secondary {
        background: $secondary;
        border-color: $secondary;
        color: $black;
        &:hover {
            background: transparent;
            border-color: $black;
            color: $black;
        }
    }

    /* btn-outline-secondary */
    &.btn-outline-secondary {
        background-color: transparent;
        border-color: $secondary;
        color: $secondary;
        &:hover {
            background: transparent;
            border-color: $black;
            color: $black;
        }
    }
}

.bg-dark {
    .btn {
        /* btn-primary */
        &.btn-primary {
            background: $rose-fog;
            border-color: $rose-fog;
            color: $black;
            &:hover {
                background: transparent;
                border-color: white;
                color: white;
            }
        }

        /* btn-outline-primary */
        &.btn-outline-primary {
            background: transparent;
            border-color: $rose-fog;
            color: white;
            &:hover {
                background: transparent;
                border-color: white;
                color: white;
            }
        }

        /* btn-secondary */
        &.btn-secondary {
            background-color: $tertiary;
            border-color: $tertiary;
            color: white;
            &:hover {
                background: transparent;
                border-color: white;
                color: white;
            }
        }

        /* btn-outline-secondary */
        &.btn-outline-secondary {
            background: transparent;
            border-color: $tertiary;
            color: white;
            &:hover {
                background: transparent;
                border-color: white;
                color: white;
            }
        }
    }
}
/*  btn-back
    ========================================================================== */
.btn-back {
    display: inline-block;
    color: black;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    &::before {
        display: inline-block;
    }
}

/*  btn-down
    ========================================================================== */
.btn-down {
    @extend .btn;
    @extend .btn-primary;
    color: black !important;
    background-color: $grey-goose !important;
    padding-right: 27px;
    // transform: none;
    // @include media-breakpoint-up(md) {
    //     transform: translateY(170px);
    // }
    // @include media-breakpoint-up(xl) {
    //     transform: translateY(130px);
    // }
    &::after {
        content: "\f063";
        position: static;
        margin-left: 55px;
        border: 3px solid black;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
    }

    &:hover {
       color: black !important;
       box-shadow: $box-shadow;
       border-color: $grey-goose !important;
    }
}
