.header {
  position: relative;
  z-index: 999;
  &, * {
    transition: $transition-base;
  }

  [class*="container-"] {
    .column.default {
      @extend .align-items-center;
      @extend .navbar-expand-xl;
      background: $white;
      display: flex;
      flex-flow: row wrap;
      padding: 10px 2rem;

      @include media-breakpoint-up(xl) {
        flex-wrap: nowrap;
        align-items: flex-start;
      }

      .socials {
        @extend .flex-wrap;
      }
    }
  }
}
