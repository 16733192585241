.menu {
  .navbar-nav a.nav-link {
    color: $body-color;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-nav .nav-item:hover > a.nav-link,
  .navbar-nav .nav-item.active > a.nav-link {
    color: $primary;
  }

  // horizontal menu
  &.menu {
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    .navbar-collapse {
      @extend .justify-content-end;
    }
  }

  // todo: check side-panel
  // side panel
  &.side-panel {
    &.side-panel-opened {
      .nav-item.active-side-panel {
        display: inherit;

        .dropdown-toggle {
          display: none !important;
        }

        .nav-item {
          display: inherit;
        }
      }
      .nav-item:not(.active-side-panel) {
        display: none;
      }
    }

    .menu-side-panel-header {
      .go-back::after {
        content: "\F060";
        font-family: "Font Awesome 5 Pro";
        font-size: 0.813rem;
        font-weight: 700;
        border: none;
      }
    }
  }
}
