.footer {
	margin-top: $grid-gutter-width * 4;
	@include media-breakpoint-up(sm) {
		margin-top: $grid-gutter-width * 6;
	}
}
> footer > section {
	&.footer-logos {
		position: relative;
		background-color: $secondary;
		padding-top: $grid-gutter-width * 2;
		@include media-breakpoint-up(sm) {
			padding-top: 0;
		}
		&::before {
			content: "";
			position: absolute;
			top: -45px;
			background: url("/images/footer-top-wave.svg") no-repeat top / contain;
			width: 100%;
			height: 125px;
			@include media-breakpoint-up(sm) {
				top: -125px;
				background: url("/images/footer-top-wave.svg") no-repeat top / cover;
			}
		}
		.container-one-column {
			.container-holder {
				.column {
					.page-block {
						margin-bottom: ($grid-gutter-width / 1.2);

						h2 {
							color: white;
							font-size: $h4-font-size;

							@include media-breakpoint-up(sm) {
								font-size: $h1-font-size;
							}
						}
					}
				}
			}
		}
	}

	&.footer-outro {
		background-color: $secondary;
		padding-bottom: $grid-gutter-width * 4;
		@include media-breakpoint-up(sm) {
			padding-bottom: $grid-gutter-width * 7;
		}
		.container-three-columns {
			.container-holder {
				.column {
					.page-block {
						h3 {
							font-size: $font-size-base * 1.25;
							color: white;
						}
						p {
							color: white;
							font-size: $font-size-base_sm;
							line-height: 2;
							margin-bottom: 0;
							text-decoration: underline;
							a {
								color: white;
								text-decoration: underline;

								&:hover {
									color: black;
								}
							}
						}
					}
					ul.vocabulary-term-list {
						@include flexbox;
						padding-left: 0;

						li {
							margin-right: $grid-gutter-width / 1.5;
							margin-bottom: 0;
							a,i {
								color: white;
								font-size: $h6-font-size;
								transition: 0.5s;

								&:hover {
									color: black;
								}
							}
							span {
								display: none;
							}
							
						}
					}
					&.one ,&.two {
						margin-bottom: $grid-gutter-width * 1.5;

						@include media-breakpoint-up(sm) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	&.footer-socket {
		position: relative;
		background-color: $rodeo-dust;
		padding-top: $grid-gutter-width;
		@include media-breakpoint-up(sm) {
			padding-top: 0;
		}
		&::before {
			content: "";
			position: absolute;
			top: -35px;
			background: url("/images/footer-bottom-wave.svg") no-repeat top / contain;
			width: 100%;
			height: 110px;
			@include media-breakpoint-up(sm) {
				top: -105px;
				background: url("/images/footer-bottom-wave.svg") no-repeat top / cover;
			}
		}

		.container-one-column {
			.container-holder {
				.column {
					.page-block.footer-text {
						p {
							font-size: $font-size-base;
						}
					}
					ul.vocabulary-term-list {
						@extend .mx-auto;
						margin-bottom: $grid-gutter-width;
						li {
							&:first-of-type {
								a {
									i {
										&::before {
											content: "\f095" !important;
										}
									}
								}
							}
							span {
								display: none;
							}
							a,
							i {
								text-decoration: none;
								transition: 0.5s;

								&:hover {
									color: white;
									background-color: $secondary;
									border-color: $secondary;
								}
							}

							i {
								border: 1px solid black;
								padding: 9px 11px;
								border-radius: 50%;
								width: 36px;
								height: 36px;
								@include flexbox;
								@include justify-content($value: center);
								@include align-items($value: center);
							}
						}
					}
					.footer-link {
						margin-bottom: $grid-gutter-width / 1.5;
						ul {
							@include justify-content($value: center);

							li {
								margin: 0 ($grid-gutter-width) 0 0;
								@extend .text-center;
								a {
									font-size: $font-size-base_sm;
									text-decoration: none;

									&:hover {
										color: white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
