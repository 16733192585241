.collection.grid {
	width: 100%;
	@extend .mx-auto;
	@include media-breakpoint-up(sm) {
		width: 100%;
		max-width: 576px;
	}
	@include media-breakpoint-up(md) {
		width: 100%;
		max-width: 720px;
	}
	@include media-breakpoint-up(lg) {
		width: 100%;
		max-width: 992px;
	}
	@include media-breakpoint-up(xl) {
		width: 100%;
		max-width: 1200px;
	}
	@include media-breakpoint-up(xxl) {
		width: 100%;
		max-width: 1480px;
	}
	@include media-breakpoint-up(xxxl) {
		width: 100%;
		max-width: 1660px;
	}

	.grid-items {
		.item {
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}

			.card {
				&-image {
					.card-image-caption {
						width: 100px;
						height: 100px;
						overflow: hidden;
						background: $tertiary;
						transform: rotate(45deg);
						right: 35px;
						bottom: -35px;
						left: auto;

						.card-image-label {
							position: absolute;
							margin: auto;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							background: transparent;
							transform: rotate(315deg);
							color: $white;
							font-size: 0.9375rem;
							font-weight: $font-weight-regular;
							white-space: normal;
							display: flex;
							justify-content: center;
							align-items: center;
							text-align: center;
						}
					}
				}

				&-body {
					padding: $grid-gutter-width;

					.card-caption {
						@include flexbox;
						@include flex-direction($direction: column);
						margin-bottom: $grid-gutter-width;

						.card-title-link {
							order: 2;
							margin-bottom: 0;

							.card-title {
								font-size: $h4-font-size;

								@include media-breakpoint-up(xl) {
									font-size: $h2-font-size;
								}
							}
						}

						.card-subtitle {
							order: 1;
							font-weight: $font-weight-regular;
						}

						.card-description {
							order: 3;

							&-content {
								margin-top: $grid-gutter-width / 1.5;
							}

							ul {
								padding-left: 0;

								li {
									font-size: $font-size-base;

									i {
										font-weight: $font-weight-regular;
									}
								}
							}
						}

						.card-prices {
							order: 4;
							margin-top: $grid-gutter-width / 1.5;
						}
					}

					.card-buttons {
						.card-btn {
							color: black;
							padding: 0 ($input-btn-padding-x) 0 0;
							background-color: transparent;
							border-color: transparent;
							box-shadow: none !important;
							transition: 0.5s;

							&::after {
								transition: 0.5s;
								right: 25px;
								top: 17px;
							}

							&:hover {
								color: $secondary;

								&::after {
									right: 20px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.card-img-overlay {
	display: none !important;
}

.gallery-caption {
	display: none !important;
}
