// new base file - january 2022

// colors
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
	background-color: $primary;
	color: $white;
}

#TommyBookingSupport .tbs-btn-info {
	background-color: $secondary;
	border-color: $secondary;
	color: $white;
}

#TommyBookingSupport .tbs-btn-info:hover {
	background-color: $primary;
	border-color: $primary;
	color: $white;
}

#TommyBookingSupport .tbs-btn-success {
	background-color: $secondary;
	border-color: $secondary;
	color: $white;
}

#TommyBookingSupport .tbs-btn-success:hover {
	background-color: $primary;
	border-color: $primary;
	color: $white;
}

#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
	transition: 0.5s;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	background-color: $primary !important;
}

// include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid {
	padding: 0;
}

#TommyBookingSupport .tbs-navbar-collapse {
	padding: 0;
}

#TommyBookingSupport .tbs-navbar-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
}

#TommyBookingSupport .tbs-navbar {
	min-height: auto;
	overflow: hidden;
}

#TommyBookingSupport * a {
	text-decoration: none;
}

#TommyBookingSupport .tbs-legenda {
	background: none;
	border: none;
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2) {
	padding: 20px 0;
	margin: 20px 0;
	background-color: #f8f8f8; // custom
	border-radius: 5px;
}

#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
	margin: 33px 0;
	padding: 20px;
}

#TommyBookingSupport .tbs_overlay-mask {
	border-radius: 5px;
}

#TommyBookingSupport .tbs-calendar {
	margin: 20px 0;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar {
	cursor: pointer;
	transition: all 0.5s ease;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover {
	opacity: 0.8;
}

#TommyBookingSupport .tbs-calendar table > thead > tr > th {
	border: none;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	color: #fff;
	background-color: #105251;
}

#TommyBookingSupport .tbs-row h2 {
	margin-bottom: 0;
}

#TommyBookingSupport .tbs-btn-block + .tbs-btn-block {
	margin: 0;
}

#TommyBookingSupport .tbs_overzicht .tbs-row h2 {
	margin-bottom: 15px;
}

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row {
	margin-top: 15px;
}

#TommyBookingSupport .tbs-alert-danger {
	color: #e91e63;
	background-color: rgba(233, 30, 99, 0.09);
	border: none;
	margin: 0 15px 15px;
	padding: 10px 35px 10px 10px;
}

#TommyBookingSupport .tbs-has-error .tbs-form-control {
	border-color: rgb(244, 67, 54);
}

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row {
	margin-bottom: 15px;
}

#TommyBookingSupport .tbs-label {
	font-size: 100%;
	margin-right: 5px;
}

#TommyBookingSupport .tbs_kassabon .tbs-hr {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate {
	font-weight: 400;
}

#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount {
	font-size: 32px;
	line-height: 50px;
}

#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row {
	margin: 0 0 15px 0;
}

/*Boeken page*/
#TommyBookingSupport .tbs-bind.tbs-btn {
	background-color: $rose-fog;
	border-color: $rose-fog;
	color: $white;
	width: 36px;
	height: 36px;
	border-radius: 6px;

	i {
		font-family: $font-awesome;
		font-size: $font-size-base * 1.25;
		display: flex;
		justify-content: center;
	}

	.tbs-glyphicon-chevron-right {
		&::before {
			content: "\F061" !important;
		}
	}

	.tbs-glyphicon-chevron-left {
		&::before {
			content: "\F060" !important;
		}
	}

	&:hover {
		background-color: transparent;
		border-color: $rose-fog;
		color: $rose-fog;
	}
}

#TommyBookingSupport .tbs-btn-success {
	background: $rose-fog;
	border-color: $rose-fog;
	color: $black;
	font-weight: $font-weight-medium;

	&:hover {
		background: $secondary;
		border-color: white;
		color: white;
	}

	&::after {
		content: none;
	}
}

#TommyBookingSupport {
	.tbs-container-fluid {
		.tbs-row {
			div.tbs-col-lg-8 {
				background-color: $secondary;
				padding: 40px 70px;
				border-radius: 0px 0px 6px 6px;
				position: relative;

				.tbs-container-fluid {
					.tbs-navbar-collapse {
						border: none;

						.tbs-navbar-nav {
							display: flex;
							justify-content: center;
							align-items: center;
							float: none;
							background-color: white;
							padding-left: 0;
							margin-bottom: 0;

							li {
								margin-bottom: 0;

								&::before {
									content: none;
								}

								a {
									display: flex;
									color: $black;
									padding: 10px;
									background-color: transparent;
									font-size: $font-size-base_sm;
									font-weight: $font-weight-bold;

									i {
										margin-right: 5px;
									}
								}

								&.tbs-active,
								&:hover {
									a {
										background-color: white;
										color: $secondary;
									}
								}

								&:not(:nth-last-child(2)) {
									&::after {
										content: "";
										border-right: 2px solid #3c597d;
										height: 80%;
										width: 2px;
										position: absolute;
										right: 0;
										top: 4px;
										opacity: 0.8;
									}
								}
							}
						}
					}
				}

				.tbs-page-verblijf {
					.tbs-container-fluid {
						.tbs_persoonscategorien,
						.tbs_accommodaties {
							background: transparent;

							label.tbs-translate {
								font-size: 18px;
								color: white;
								//font-weight: $font-weight-semi-bold;
							}

							div.tbs-translate {
								font-size: 16px;
								color: white;
							}

							.tbs-form-control {
								height: 34px;
								border-radius: 0;
								color: $black;
								font-size: $font-size-base_sm;
								// font-weight: $font-weight-semi-bold;
							}

							.tbs-row {
								margin-bottom: 10px;
							}
						}

						.tbs_accommodaties {
							.tbs-form-control {
								height: 36px;
								font-weight: $font-weight-regular;
							}
						}

						.tbs_kalenders {
							background-color: transparent;

							div {
								.tbs-row {
									.tbs-col-xs-6.tbs-col-md-8 {
										padding: 0;
									}

									.tbs-calendar {
										.tbs-day {
											border-radius: 0;
										}

										.tbs-table {
											.tbs-month,
											.tbs-translate {
												font-size: $font-size-base_sm;
												color: white;
												font-weight: $font-weight-regular;
												opacity: 1;
											}

											thead {
												tr {
													&:first-of-type {
														th {
															border: none;
														}
													}

													th {
														border-width: 2px 0 2px 0;
														border-color: $rodeo-dust;
														border-style: solid;
													}
												}
											}

											tbody {
												.tbs-day div {
													color: $black;
													font-size: $font-size-base_sm;
												}

												.tbs-day.tbs-verleden {
													background-color: white !important;
													border: none;
												}
											}
										}
									}

									.tbs-legenda {
										ul {
											li {
												.tbs-day {
													width: 17px;
													height: 17px;

													&::after {
														content: none;
													}
												}

												.tbs-translate {
													font-size: $font-size-base_sm;
													color: white;
												}
											}
										}
									}
								}
							}

							.tbs-bind {
								box-shadow: $box-shadow;

								i {
									color: black;
								}
							}
						}
					}
				}
			}
		}
	}

	.tbs-btn-success {
		color: black;
		font-weight: $font-weight-medium;
		box-shadow: $box-shadow;
		border-radius: 18px;
		padding: 7px 30px;
	}

	.tbs_kassabon {
		background-color: transparent !important;

		.tbs-totaal {
			color: $primary;
			font: {
				size: 25px !important;
				weight: $font-weight-bold;
			}

			span.tbs-translate {
				font-weight: $font-weight-bold !important;
			}

			span#totaal_amount {
				font-size: 25px !important;
			}
		}

		.tbs_kassabon_overzicht {
			.tbs-col-xs-12 {
				.tbs-row {
					.tbs-col-xs-12 {
						padding: 0;

						h2 {
							color: black;
							font-size: 18px;
							font-weight: $font-weight-regular !important;
							margin-top: 20px;
						}
					}

					.tbs-col-xs-8 {
						strong {
							margin-left: -15px;
						}
					}

					.tbs-translate,
					.tbs-col-xs-9 {
						padding: 0;
						font-weight: 400;
					}
				}

				.tbs-specificaties {
					.tab-row {
						margin-bottom: 0 !important;
					}
				}
			}
		}

		.tbs-hr {
			border-top: 2px solid rgba(0, 0, 0, 0.1);
		}
	}

	ul {
		padding-left: 0;
		margin-bottom: 0;

		li {
			&::before {
				content: none;
			}
		}
	}
}

/* resonsive mobile */
@media (max-width: 767px) {
	#TommyBookingSupport .tbs-calendarPrevious span.tbs-translate,
	#TommyBookingSupport .tbs-calendarNext span.tbs-translate {
		display: none;
	}
}


.tbs_talen {
	display: none;
}

h2.tbs-totaal > span {
	color: #000;
}
