// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
	@extend .clearfix;
}

.search-book .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left {
	background: $secondary;
	@include media-breakpoint-up(sm) {
		width: 33.33333%;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-right {
	@include media-breakpoint-up(sm) {
		width: 66.66667%;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
	@include flexbox;
	background: $secondary;
	box-shadow: $box-shadow;
	padding: $grid-gutter-width / 1.2;
	@include media-breakpoint-down(sm) {
		@include flex-wrap($value: wrap);
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
	display: none;
}

.search-book .tommy-zeb-wrapper * h2 {
	font-size: 18px;
	font-weight: 700;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
	margin-top: 0 !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
	@extend .btn-primary;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
	margin-bottom: 10px;
	color: $white;
	font-size: $h5-font-size;
	font-weight: $font-weight-bold;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
	color: #fff;
	font-weight: 700;
}

.tommy-zeb-results-label {
	@include media-breakpoint-up(sm) {
		position: absolute;
		top: -30px;
		left: 0;
		transform: translateX(-30%);
		margin-top: -($grid-gutter-width);
	}
}

.tommy-zeb-result-description {
	ul {
		padding-left: 16px;

		li {
			font-size: $font-size-base_sm;
			color: white;
			margin-bottom: 0;

			&::before {
				top: 4px;
			}

			font {
				color: white;
			}
		}
	}
}

.tommy-zeb-result-actions {
	display: flex;
	justify-content: flex-end;
}

.tommy-zeb-btn.ng-binding {
	@extend .btn;
	background: $rose-fog !important;
	border-color: $rose-fog !important;
	color: $black !important;

	&:hover {
		background: transparent !important;
		border-color: white !important;
		color: white !important;
	}
}

.search-book {
	select {
		background-color: white !important;
	}

	.tommy-zeb-characteristics {
		.ng-isolate-scope {
			.ng-scope {
				&:last-of-type {
					strong {
						line-height: 3;
					}
				}
			}
		}
	}

}

@media (min-width: 576px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		width: 200px;
		height: 150px;
	}
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
		width: 520px;
	}
}

@media (min-width: 980px) and (max-width: 1199px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		margin-bottom: 15px;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
		margin-right: 0;
	}
}
