// custom-list-extra
.custom-list-extra {
    list-style-type: none;
    padding-left: ($grid-gutter-width / 1.5);
    margin-bottom: $grid-gutter-width;
    li {
        position: relative;
         &::before {
            content: "\f111";
            position: absolute;
            top: 30%;
            left: -18px !important;
            font-family: $font-awesome;
            font-size: $font-size-base / 2;
            font-weight: $font-weight-bold;
            opacity: 0.8;
        }
    }
}

// custom-list
.custom-list {
    padding-left: 20px !important;
    list-style-type: none;
    li {
        margin-bottom: 5px;
        padding-left: 5px;

        &::before {
            content: "\f061" !important;
            font-family: $font-awesome;
            font-size: $h6-font-size !important;
            top: 10% !important;
            margin-right: $grid-gutter-width / 3;
        }
    }
}
// ol
ol {
    padding-left: 0;
    list-style: none;
    counter-reset: li;
    li {
        margin-bottom: 5px;
        &::before {
            counter-increment: li;
            content: counter(li) ".";
            margin-right: 10px;
            margin-bottom: 5px;
            font-size: $h6-font-size;
            font-weight: $font-weight-bold;
            @extend .d-inline-flex;
            @extend .align-items-center;
            @extend .justify-content-between;
        }
    }
}
ul,
ol {
    list-style-type: none;
    li {
        color: $black;
        text-align: left;
        margin-bottom: $grid-gutter-width / 2.5;
        font: {
            size: $h6-font-size;
            weight: $font-weight-regular;
        }
    }
}

.main {
    ul {
        @extend .custom-list-extra;
    }
}

