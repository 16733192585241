// headings
h1 {
	font-size: $h2-font-size;
	@include media-breakpoint-up(xl) {
		font-size: $h1-font-size;
	}
}

h2 {
	font-size: $h3-font-size;
	@include media-breakpoint-up(xl) {
		font-size: $h2-font-size;
	}
}

h3 {
	font-size: $h3-font-size;
}

h4 {
	font-size: $h4-font-size;
}

h5 {
	font-size: $h5-font-size;
}

h6 {
	font-size: $h6-font-size;
}

p {
	font-size: $font-size-base;
	font-weight: $font-weight-regular;

	@include media-breakpoint-up(sm) {
		font-size: $h6-font-size;
	}
}

// img
img {
	max-width: 100%;
	height: auto;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

a {
	color: black;

	&:hover {
		color: $secondary;
	}
}

.bg-dark {
	a {
		color: white;

		&:hover {
			color: black;
		}
	}
}

//blocks
.block-1 {
	position: relative;
	overflow: hidden;

	.resource-image {
		height: 100%;

		.img-fluid {
			object-fit: cover;
			width: auto;
			height: 100%;
			border-radius: 0;
		}
	}
}

.block-2 {
	background: $quaternary;
	margin: 0 15px;
	max-width: 92%;
	border-radius: 0 0 12px 12px;
	@include media-breakpoint-up(sm) {
		flex: 0 0 460px;
		margin: 0;
		border-radius: 12px 0 0 12px;
	}

	.page-block.title {
		h1 {
			font-size: $h4-font-size;

			@include media-breakpoint-up(sm) {
				font-size: $h3-font-size;
			}
		}
	}

	.page-block.wysiwyg {
		p {
			a {
				position: relative;
				padding-right: $grid-gutter-width / 1.2;
				font-size: $font-size-base;
				font-weight: $font-weight-bold;
				text-decoration: none;
				transition: 0.5s;

				&::after {
					transition: 0.5s;
				}

				&:hover {
					color: $secondary;

					&::after {
						right: -($grid-gutter-width / 6);
					}
				}

				&::after {
					content: "\f061";
					position: absolute;
					right: 0;
					top: -2px;
					font-family: $font-awesome;
					font-size: $h6-font-size;
					font-weight: $font-weight-bold;
				}
			}
		}
	}

	.resource-image {
		height: 100%;

		.img-fluid {
			object-fit: cover;
			width: auto;
			height: 100%;
			border-radius: 0;
		}
	}
}

.block1,
.block2,
.block3 {
	.resource-image {
		height: 100%;

		.img-fluid {
			object-fit: cover;
			width: auto;
			height: 100%;
			border-radius: 0;
		}
	}
}

//gallery
.gallery-item {
	.gallery-link {
		.gallery-img, .gallery-caption {
			border-radius: 12px;
			box-shadow: $box-shadow;
		}
	}
}

.brown {
	background-color: $primary !important;
	box-shadow: $box-shadow;
}

@media only screen and (max-width: 600px) {

	.mini-sab {
		.container {
			.container-holder {
				margin-top: 108px !important;
			}

		}
	}

}

@media(max-width: 991px) {
	#carousel_614 {
		min-height: 450px !important;

		&.slider-home {
			.item {
				min-height: 450px !important;
				background-size: cover;
				background-position: bottom;
			}
		}
	}
}
