/* 	bg-light/bg-dark
    ========================================================================== */
section.bg-light,
section.bg-dark {
	margin: 0;
	padding: 6vh 0;
}

section.booking-section {
	padding: 10vh 0 !important;
}


/* 	lead-section
    ========================================================================== */
//&.home .lead-section {
//	&::before {
//		content: "";
//		position: absolute;
//		top: 15px;
//		width: 100%;
//		height: 143px;
//		transform: translateY(-100%);
//		background: url("/images/curve.png") no-repeat 12% 2% / cover;
//		background-size: 100%;
//		background-position: bottom;
//		z-index: 1;
//
//		@include media-breakpoint-up(sm) {
//			top: -230px;
//			height: 290px;
//			transform: none;
//			background: url("/images/curve.png") no-repeat top / cover;
//			background-size: 100%;
//			background-position: bottom;
//		}
//		@include media-breakpoint-up(xl) {
//			top: -210px;
//		}
//		@include media-breakpoint-up(xxl) {
//			top: -295px;
//			height: 300px;
//		}
//	}
//}

.lead-section {
	position: relative;
	padding: ($grid-gutter-width * 2) 0;
	background-color: $primary;
	@include media-breakpoint-up(sm) {
		padding: 20px 0 ($grid-gutter-width * 2);
	}

	.container-one-column {
		.container-holder {
			position: relative;
			z-index: 2;

			.column {
				.page-block.title,
				.page-title,
				& {
					color: $white;

					h1 {
						font-size: $font-size-base * 3.125;
						color: $white;
						@include media-breakpoint-up(sm) {
							font-size: $font-size-base * 3.75;
						}
						@include media-breakpoint-down(sm) {
							font-size: $font-size-base * 2.475;
						}
					}

					h5 {
						color: $white;
						min-height: 29px;
					}
				}

				.page-block.wysiwyg {
					p {
						&:first-of-type {
							font-size: $h5-font-size;
						}
					}

					ul {
						@include flexbox;
						@include flex-wrap($value: wrap);
						padding-left: $grid-gutter-width;
						margin-top: $grid-gutter-width * 1.67;
						margin-bottom: 0;

						li {
							font-size: $font-size-base_sm;
							font-weight: $font-weight-bold;
							margin-bottom: $grid-gutter-width / 3;
							width: 100%;
							color: $white;
							@include media-breakpoint-up(sm) {
								font-size: $font-size-base;
								margin-bottom: 0;
								width: max-content;
							}

							&:not(:last-of-type) {
								@include media-breakpoint-up(sm) {
									margin-right: $grid-gutter-width * 2.5;
								}
							}

							&::before {
								content: "\f058" !important;
								font-size: $h6-font-size;
								font-weight: $font-weight-light;
								top: -5px;
								left: -30px !important;

								@include media-breakpoint-up(sm) {
									font-size: $h5-font-size;
								}
							}
						}
					}
				}
			}
		}
	}

	/* bundles */
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		@include flexbox;
		@include flex-direction($direction: column);

		.subtitle {
			order: 1;
		}

		.title {
			order: 2;
		}

		.page-block.wysiwyg {
			order: 3;
		}

		// accommodation
		.properties {
			order: 4;
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;
			padding-left: 0;

			li {
				color: $white;
				margin: 0 ($grid-gutter-width * 1.5) 0 0;
				@include media-breakpoint-up(lg) {
					margin: 0 ($grid-gutter-width * 2.5) 0 0;
				}

				&::before {
					content: none;
				}
			}

			.icon {
				margin-right: ($grid-gutter-width / 3);
				font-size: $h5-font-size;

				i {
					font-weight: $font-weight-light;
				}
			}

			.label {
				font-size: $font-size-base;
				font-weight: $font-weight-bold;
			}
		}
	}

	&.bg-dark {
		&::before {
			content: none;
		}
	}

	.btn-back {
		color: $white;
	}
}

/* 	content-section
    ========================================================================== */
.content-section {
	padding: ($grid-gutter-width * 2) 0;

	&.bg-dark {
		padding: 0 0 ($grid-gutter-width * 2);
		background-color: $secondary !important;
		@include media-breakpoint-up(sm) {
			padding: 0 0 ($grid-gutter-width * 2.83);
		}

		.slider {
			padding: 0;
		}

		.info {
			padding-top: ($grid-gutter-width / 3);
			padding-bottom: ($grid-gutter-width * 2);
			@include media-breakpoint-up(sm) {
				padding-top: ($grid-gutter-width * 2);
			}


			.container-holder {
				position: relative;
				z-index: 2;

				.column {
					.page-block.wysiwyg {
						p {
							&:first-of-type {
								font-size: $font-size-base * 1.25;
								@include media-breakpoint-up(sm) {
									font-size: $h5-font-size;
								}
							}
						}
					}

					.homepage-icons-block {
						@include media-breakpoint-down(sm) {
							overflow-x: scroll;
							overflow-y: visible;
						}

						.item {
							> .icon {
								height: auto !important;
							}
						}

						.card-body {
							flex: 0 0 auto !important;

							.card-caption {
								margin-bottom: 0 !important;
								flex: 0 0 auto !important;
							}

						}
					}

					.grid {
						margin-top: $grid-gutter-width;

						&-items {
							@include justify-content($value: flex-start);
							border-radius: 12px;
							flex-wrap: nowrap;
							@include media-breakpoint-down(sm) {
								margin: auto;
							}

							.item {
								height: 94px;
								margin: 0;
								flex: 0 0 100px;
								border: 1px solid rgba(white, 0.4);

								&:not(:last-of-type) {
									border-right: none;
								}

								&:first-of-type {
									border-top-left-radius: 12px;
									border-bottom-left-radius: 12px;
								}

								&:last-of-type {
									border-top-right-radius: 12px;
									border-bottom-right-radius: 12px;
								}

								.card {
									background-color: transparent;
									border: 0;
									margin-top: $grid-gutter-width / 1.5;

									&-image,
									&-body {
										background-color: transparent;
									}

									&-image {
										i {
											font-size: $h5-font-size;
											font-weight: $font-weight-bold;
										}
									}

									&-body {
										padding: ($grid-gutter-width / 2) 0 0 0;

										.card-caption {
											@extend .text-center;

											.card-title {
												color: white;
												font-size: $font-size-base;
												font-weight: $font-weight-regular;
											}
										}

										.card-buttons {
											display: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.container-one-column {
			&:nth-of-type(3) {
				position: relative;
				margin-bottom: $grid-gutter-width * 2;

				@include media-breakpoint-up(sm) {
					margin-bottom: $grid-gutter-width * 2.5;
				}

				&::before {
					content: "";
					position: absolute;
					top: 113px;
					left: -50px;
					width: 100%;
					height: 600px;
					transform: matrix(0.99, -0.1, 0.1, 0.99, 0, 0);
					background: url("/images/middel11.png") no-repeat top/cover;
				}
			}
		}

		.container-one-column {
			&:nth-of-type(4) {
				.container-holder {
					.column {
						.page-block.link {
							@extend .mx-auto;
						}
					}
				}
			}
		}
	}

	&.intro-text {
		position: relative;
		overflow-x: hidden;

		&::before {
			content: "";
			position: absolute;
			top: 40%;
			left: 0;
			right: 0;
			width: 100%;
			height: 1185px;
			z-index: -1;
			background: url("/images/group34.svg") no-repeat;
			background-size: cover;
			background-position: center;

			@include media-breakpoint-up(xl) {
				top: 20%;
				left: -345px;
				background-size: contain;
			}
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 10%;
			left: 0;
			width: 100%;
			height: 697px;
			z-index: -1;
			background: url("/images/turtle.png") no-repeat;
			background-size: cover;
			background-position: center;

			@include media-breakpoint-up(xl) {
				background-size: contain;
				left: 24%;
			}
		}

		.container-one-column {
			margin: ($grid-gutter-width) auto ($grid-gutter-width * 1.5);
			@include media-breakpoint-up(sm) {
				margin: ($grid-gutter-width * 2.5) auto ($grid-gutter-width * 2.83);
			}

			.container-holder {
				max-width: 850px;
				@extend .mx-auto;

				.column {
					.page-block.title {
						h5 {
							@extend h2;
						}
					}

					.page-block.wysiwyg {
						p {
							&:first-of-type {
								font-size: $font-size-base * 1.25;
								@include media-breakpoint-up(sm) {
									font-size: $h5-font-size;
								}
							}
						}
					}
				}
			}
		}

		.container-two-columns {
			position: relative;

			&.block1 {
				margin-bottom: $grid-gutter-width * 1.5;

				@include media-breakpoint-up(sm) {
					margin-bottom: $grid-gutter-width * 2.67;
				}

				.container-holder {
					.column {
						position: relative;

						&.one {
							order: 2;
							padding: ($grid-gutter-width * 1.5) ($grid-gutter-width);
							@extend .block-2;
							@include media-breakpoint-up(sm) {
								order: 1;
								margin-bottom: 0;
								padding: ($grid-gutter-width * 2.5) ($grid-gutter-width * 1.33);
							}
						}

						&.two {
							order: 1;
							margin-bottom: 0;
							@include media-breakpoint-up(sm) {
								order: 2;
								flex: 0 0 calc(100% - 460px);
								max-width: calc(100% - 460px);
								margin: 0;
								padding-right: 0 !important;
								padding-left: 0 !important;
							}

							.page-block.resource-image {
								@extend .block-1;
								border-radius: 12px 12px 0 0;
								@include media-breakpoint-up(sm) {
									border-radius: 0 12px 12px 0;
									overflow: hidden;
								}
							}
						}
					}
				}
			}

			&.block2 {
				margin-bottom: $grid-gutter-width * 1.5;

				@include media-breakpoint-up(sm) {
					margin-bottom: $grid-gutter-width * 2.67;
				}

				.container-holder {
					.column {
						position: relative;

						&.one {
							margin-bottom: 0;

							.page-block.resource-image {
								@extend .block-1;
								border-radius: 12px 12px 0 0;
								@include media-breakpoint-up(sm) {
									border-radius: 12px 0 0 12px;
								}
							}

							@include media-breakpoint-up(sm) {
								flex: 0 0 calc(100% - 460px);
								max-width: calc(100% - 460px);
								margin: 0;
								padding-right: 0 !important;
								padding-left: 0 !important;
							}
						}

						&.two {
							@extend .block-2;
							padding: ($grid-gutter-width * 1.5) ($grid-gutter-width);
							@include media-breakpoint-up(sm) {
								border-radius: 0 0 12px 12px;
								padding: ($grid-gutter-width * 2.5) ($grid-gutter-width * 1.33);
								border-radius: 0 12px 12px 0;
							}
						}
					}
				}
			}

			&.block3 {
				margin-bottom: $grid-gutter-width;

				@include media-breakpoint-up(sm) {
					margin-bottom: $grid-gutter-width * 4;
				}

				.container-holder {
					.column {
						position: relative;

						&.one {
							order: 2;
							padding: ($grid-gutter-width * 1.5) ($grid-gutter-width);
							@extend .block-2;
							@include media-breakpoint-up(sm) {
								order: 1;
								margin-bottom: 0;
								padding: ($grid-gutter-width * 2.5) ($grid-gutter-width * 1.33);
							}
						}

						&.two {
							order: 1;
							margin-bottom: 0;
							@include media-breakpoint-up(sm) {
								order: 2;
								flex: 0 0 calc(100% - 460px);
								max-width: calc(100% - 460px);
								margin: 0;
								padding-right: 0 !important;
								padding-left: 0 !important;
							}

							.page-block.resource-image {
								@extend .block-1;
								border-radius: 12px 12px 0 0;
								@include media-breakpoint-up(sm) {
									border-radius: 0 12px 12px 0;
									overflow: hidden;
								}
							}
						}
					}
				}
			}
		}
	}

	&.block {
		margin-top: 0;
		padding: ($grid-gutter-width * 2) 0 0;
		@include media-breakpoint-up(sm) {
			margin-top: $grid-gutter-width * 3;
			padding: ($grid-gutter-width) 0 0;
		}

		.container-three-columns {
			.container-holder {
				.column {
					.page-block.title {
						h1 {
							font-size: $h4-font-size;
						}
					}

					.page-block.wysiwyg {
						p {
							a {
								position: relative;
								padding-right: $grid-gutter-width / 1.2;
								font-size: $font-size-base;
								font-weight: $font-weight-bold;
								text-decoration: none;
								transition: 0.5s;

								&::after {
									transition: 0.5s;
								}

								&:hover {
									color: $secondary;

									&::after {
										right: -($grid-gutter-width / 6);
									}
								}

								&::after {
									content: "\f061";
									position: absolute;
									right: 0;
									top: -2px;
									font-family: $font-awesome;
									font-size: $h6-font-size;
									font-weight: $font-weight-bold;
								}
							}
						}
					}
				}
			}
		}
	}
}

/* 	bundle-overview-section
    ========================================================================== */
.bundle-overview-section {
	padding: ($grid-gutter-width * 3) 0;
}

/* 	bundle-detail-section
    ========================================================================== */
.bundle-detail-section {
	background-color: $primary;
	padding-bottom: $grid-gutter-width * 3;
}

.gallery-section {
	background-color: $primary;
	padding-bottom: $grid-gutter-width * 3;

	.container-one-column {
		.container-holder {
			.column {
				.gallery-horizontal {
					.gallery-label {
						position: absolute;
						width: 100px;
						height: 100px;
						right: 70px;
						top: -50px;
						color: white;
						font-size: 0.9375rem;
						font-weight: $font-weight-regular;
						white-space: normal;
						display: flex;
						z-index: 1;
						justify-content: center;
						align-items: center;

						&::after {
							position: absolute;
							content: "";
							top: 0;
							left: 0;
							width: 100px;
							height: 100px;
							background: $tertiary;
							transform: rotate(45deg);
							z-index: -1;
						}
					}
				}
			}
		}
	}
}

.booking-section {
	position: relative;
	background-color: $secondary !important;
	margin: ($grid-gutter-width * 3) 0 !important;
	padding-bottom: $grid-gutter-width * 3 !important;

	&::before {
		content: "";
		position: absolute;
		top: -45px;
		background: url("/images/footer-top-wave.svg") no-repeat top / contain;
		width: 100%;
		height: 125px;
		@include media-breakpoint-up(sm) {
			top: -125px;
			background: url("/images/footer-top-wave.svg") no-repeat top / cover;
		}
	}

	.container-one-column {
		position: unset;

		&::before {
			content: "";
			position: absolute;
			top: -20px;
			left: 0;
			right: 0;
			width: 125vw;
			height: 126%;
			background: url("/images/middel11.png") no-repeat;
			background-size: 100% 80%;
			z-index: 0;
			@media(max-width: 991px) {
				max-width: 100%;
			}
		}

		.container-holder {
			.column {
				h2 {
					margin-bottom: $grid-gutter-width * 1.5;
				}
			}
		}
	}
}

.contact {
	.container-two-columns {
		.container-holder {
			.column {
				.page-block {
					h3 {
						font-size: $h4-font-size;
						font-weight: $font-weight-medium;
					}
				}

				.page-block.wysiwyg {
					p {
						&:nth-of-type(2) {
							@include flexbox;
							@include flex-direction($direction: column);
							margin-bottom: 0;

							a {
								margin-bottom: $grid-gutter-width / 3;
							}
						}
					}
				}

				table {
					tbody {
						tr {
							td {
								padding-right: 20px;
							}
						}
					}
				}

				ul.vocabulary-term-list {
					margin: ($grid-gutter-width / 2) 0 0;
					display: flex;
					align-items: center;
					padding-left: 0;

					li {
						margin: 0 ($grid-gutter-width / 2) 0 0;

						&::before {
							content: none;
						}

						a,
						i {
							color: black;
							font-size: $h5-font-size;

							&:hover {
								color: $primary;
							}
						}

						span {
							display: none;
						}
					}
				}

				.form {
					box-shadow: $box-shadow;

					.form-control {
						height: 38px;
						border-radius: 6px;
						padding: 0.9375rem 1rem;
					}

					textarea {
						height: auto !important;
					}
				}

				&.two {
					.page-block {
						h3 {
							font-size: $font-size-base * 1.25;
						}
					}
				}
			}
		}
	}
}

&.home {
	.lead-section {
		padding: 0 0 ($grid-gutter-width * 2) !important;

		.container-one-column {
			.container-holder {
				.column {
					.page-block.title {
						h5 {
							font-size: $font-size-base * 3.125;

							@include media-breakpoint-up(sm) {
								font-size: $font-size-base * 3.75;
							}
						}
					}
				}
			}
		}
	}
}

&.theme-demo {
	.lead-section {
		margin-top: 220px;

		&.bg-dark {
			background-color: $secondary !important;
			padding-top: $grid-gutter-width * 3;
			margin-top: 100px;
		}
	}

	.content-section {
		padding: ($grid-gutter-width * 2) 0 !important;

		&.bg-dark {
			background-color: $secondary !important;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 113px;
				left: -50px;
				width: 100%;
				height: 600px;
				transform: matrix(0.99, -0.1, 0.1, 0.99, 0, 0);
				background: url("/images/middel11.png") no-repeat top/cover;
			}
		}
	}
}

&.bundle-overview {
	.bundle-overview-section {
		background-color: $primary;
		padding: 0 0 ($grid-gutter-width * 4);
		margin-bottom: $grid-gutter-width * 8;
	}
}

&.accommodation-detail {
	.lead-section {
		.container-one-column {
			.container-holder {
				.column {
					.info {
						ul {
							margin-bottom: $grid-gutter-width;
						}
					}
				}
			}
		}
	}
}

&.home {
	.intro-golf-section {
		.logo {
			display: none;
		}
	}
}

.intro-golf-section {
	background: url("/images/curve.png") no-repeat;
	background-size: 100% 100%;
	background-position: left bottom;
	margin-bottom: -2px;
	margin-top: -174px;
	position: relative;
	z-index: 1;
	padding-bottom: 35px;

	.column {
		min-height: 141px;
	}

	.logo {
		max-width: 350px;
		margin-top: 25px;
	}
}

.intro-golf-section {
	.container-fluid {
		max-width: 1690px;
	}
}
