.slider,
.image-slider {
	overflow-x: hidden;

	.owl-carousel {
		min-height: 662px !important;
		@include media-breakpoint-up(sm) {
			min-height: 800px !important;
		}

		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					.item {
						min-height: 662px !important;

						@include media-breakpoint-up(sm) {
							min-height: 800px !important;
						}

						&::before {
							content: "";
							position: absolute;
							bottom: 0;
							width: 100%;
							height: 335px;
							background-color: #ca7b6a;
							@include media-breakpoint-up(sm) {
								content: none;
							}
						}

						&::after {
							content: "";
							position: absolute;
							background: url("/images/footer-top-wave.svg") no-repeat top / contain;
							width: 100%;
							left: 0;
							right: 0;
							bottom: 50px;
							height: 325px;
							z-index: 1;
							@include media-breakpoint-up(sm) {
								height: 125px;
								bottom: 0;
								background: url("/images/footer-top-wave.svg") no-repeat top / cover;
							}
						}

						.owl-caption {
							position: absolute;
							bottom: 40px;
							left: 0%;
							max-width: 444px;
							background-color: $pastel-brown;
							border-radius: 12px;
							margin: 0 ($grid-gutter-width / 2);
							padding: ($grid-gutter-width * 1.67) ($grid-gutter-width * 1.16);
							z-index: 2;

							@include media-breakpoint-up(sm) {
								left: 10%;
								margin: 0;
							}

							.owl-title {
								font-size: $h4-font-size;
								text-shadow: none;
								text-align: left;
							}

							.owl-description {
								margin: 0;
								text-align: left;

								p {
									margin-bottom: 0;
								}
							}

							.owl-btn {
								color: white;
								padding: 0 ($input-btn-padding-x) 0 0;
								background-color: transparent;
								border-color: transparent;
								box-shadow: none !important;
								transition: 0.5s;

								&::after {
									top: 16px;
									transition: 0.5s;
								}

								&:hover {
									color: black;

									&::after {
										right: 15px;
									}
								}
							}
						}
					}
				}
			}
		}

		.owl-dots {
			@include flexbox;
			@include flex-wrap($value: wrap);
			@include justify-content($value: center);
			@extend .text-center;
			position: absolute;
			left: 15%;
			right: 0;
			bottom: 340px;
			max-width: 250px;

			@include media-breakpoint-up(sm) {
				bottom: 325px;
			}

			.owl-dot {
				&.active {
					span {
						background: black;
					}
				}

				span {
					width: 40px;
					height: 8px;
					background: $grey-goose;
					border: none;
					border-radius: 4px;
					box-shadow: none;
				}
			}
		}
	}

	.owl-nav,
	.owl-dots {
		display: none;
	}
}

.image-slider {
	padding: 0;

	.owl-carousel {
		min-height: 1200px !important;
		@include media-breakpoint-up(sm) {
			min-height: 800px !important;
		}

		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					.item {
						min-height: 1200px !important;
						@include media-breakpoint-up(sm) {
							min-height: 800px !important;
						}

						&::before {
							content: "";
							position: absolute;
							top: 0;
							pointer-events: none;
							background: url("/images/circlemask.png") no-repeat;
							background-size: 500% 100%;
							width: 100%;
							height: 101%;
							margin: -1px 0 0;
							background-position: center;
							@include media-breakpoint-up(sm) {
								background-size: 200% 100%;
							}
							@include media-breakpoint-up(lg) {
								background-size: 100% 100%;
							}
						}

						&::after {
							content: none;
						}

						.owl-caption {
							right: 0;
							top: 0;
							bottom: auto;
							background-color: $dust;

							@include media-breakpoint-up(sm) {
								right: 5%;
								left: auto;
							}
						}
					}
				}
			}
		}

		.owl-dots {
			@include flexbox;
			@include flex-wrap($value: wrap);
			@include justify-content($value: center);
			@extend .text-center;
			right: 0;
			top: 15px;
			left: auto;
			bottom: auto;
			width: 100%;
			max-width: 440px;
			margin-left: auto;
			@include media-breakpoint-up(sm) {
				right: 50px;
			}
			@include media-breakpoint-up(xl) {
				right: 80px;
			}

		}

		.owl-nav {
			display: none;
		}
	}
}

.slider {
	.owl-carousel {
		min-height: 692px !important;
		@include media-breakpoint-up(sm) {
			min-height: 800px !important;
		}

		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					.item {
						min-height: 692px !important;

						@include media-breakpoint-up(sm) {
							min-height: 800px !important;
						}
					}
				}
			}
		}
	}
}
