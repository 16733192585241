.header {
	position: absolute;
	left: 0;
	right: 0;
	// responsive custom styling
	.page-block.menu-toggle {
		min-width: 0;
	}

	.page-block.logo {
		min-width: 0;
		margin: 0;
	}

	.page-block.site-switcher {
		margin: 0;
		flex-grow: 1;
	}

	// responsive mobile header order.
	.page-block.menu-toggle {
		order: 0;
	}

	.page-block.logo {
		order: 1;
	}

	.page-block.site-switcher {
		order: 2;
	}

	.page-block.menu {
		order: 3;
	}

	// Desktop resets
	@include media-breakpoint-up(xl) {
		.page-block.menu-toggle {
			order: unset;
		}
		.page-block.logo {
			order: unset;
		}
		.page-block.site-switcher {
			order: unset;
		}
		.page-block.menu {
			order: unset;
		}

		.page-block.site-switcher {
			display: flex;
			justify-content: flex-end;
			margin-left: 25px;
			flex-grow: 0;
		}

		.page-block.menu-toggle {
			margin: 0 0 0 auto;
		}
	}

	.container-one-column {
		.container-holder {
			.column {
				background-color: transparent;
				padding: ($grid-gutter-width / 3) ($grid-gutter-width / 1.5) !important;

				@include media-breakpoint-up(sm) {
					padding: ($grid-gutter-width / 3) ($grid-gutter-width * 1.33) !important;
				}

				.menu-toggle {
					.navbar-toggler {
						&[aria-expanded="true"] {
							.fa-bars {
								&::before {
									content: "\f00d";
								}
							}
						}

						&-icon {
							color: white;
							font-size: $h3-font-size;
							width: 36px;
							height: 36px;

							i {
								font-weight: $font-weight-medium;
							}
						}
					}
				}

				.page-block.logo {
					position: absolute;
					top: 100px;
					left: 50%;
					right: 0;
					width: 100%;
					max-width: 100%;
					transform: translateX(-50%);

					@include media-breakpoint-between(sm, lg) {
						width: 247px;
						left: 160px !important;
					}
					@include media-breakpoint-up(sm) {
						top: 66px !important;
					}
					@include media-breakpoint-up(md) {
						top: 100px !important;
						left: 50%;
					}
					@include media-breakpoint-up(xl) {
						left: 40px;
						transform: none;
					}

					img {
						max-height: 100%;
					}
				}

				.menu.menu {
					z-index: 2;
					@include media-breakpoint-up(xl) {
						@include justify-content($value: flex-start);
					}

					&.show {
						margin: ($grid-gutter-width / 3) (-($grid-gutter-width / 1.5));
						background-color: $primary;
					}

					.navbar-nav {
						.nav-item {
							margin-bottom: 0;

							.nav-link {
								color: white !important;
								font-size: $font-size-base;
								font-weight: $font-weight-bold;
								padding-right: 1.5rem !important;

								&:hover {
									opacity: 0.7;
								}
							}

							.dropdown-toggle {
								display: flex;
								align-items: center;
								justify-content: space-between;

								@include media-breakpoint-down(lg) {
									&[aria-expanded="true"] {
										&::after {
											content: "\f068";
										}
									}
									&::after {
										margin-right: 3px;
										content: "\F067";
										font-family: $font-awesome;
										font-size: 0.813rem;
										font-weight: 700;
										border: none;
									}
								}
							}

							&.dropdown {
								.dropdown-menu {
									background-color: $primary;
									border: 0;
									border-radius: 6px;
									box-shadow: $box-shadow;

									.nav-item {
										margin-bottom: 0;

										.nav-link {
											font-size: $font-size-base_sm;
										}
									}

									&.show {
										@include media-breakpoint-down(lg) {
											position: static !important;
											background: transparent;
											box-shadow: none;
											transform: none !important;
										}
									}
								}
							}
						}
					}
				}

				ul.vocabulary-term-list {
					margin-bottom: 0;
					position: absolute;
					right: 20px;
					top: 10px;
					@include media-breakpoint-up(xl) {
						position: unset;
					}

					li {
						a {
							@include flexbox;
							@include flex-direction($direction: column);
							color: $heather !important;
							font-size: $font-size-base_xxs;
							text-decoration: none;
							transition: 0.5s;

							&::before {
								@include flexbox;
								@include justify-content($value: center);
								@include align-items($value: center);
								font-family: $font-awesome;
								font-size: $h6-font-size;
								color: white;
								width: 36px;
								height: 36px;
								border: 1px solid #C49D66;
								border-radius: 50%;
								transition: 0.5s;
								background: #C49D66;
							}

							&:hover {
								&::before {
									background-color: white;
									color: $heather;
								}
							}
						}

						&:first-of-type {
							a {
								&::before {
									content: "\f1e0";
								}
							}
						}

						&:last-of-type {
							a {
								&::before {
									content: "\f073";
								}
							}
						}
					}
				}
			}
		}
	}

	.melding {
		display: none;
	}

	&.sticky {
		background-color: #ced4c8;

		.container-one-column {
			.container-holder {
				.column {
					.page-block.logo {
						display: none;
					}

					ul.vocabulary-term-list {
						li {
							a {
								color: white !important;

								&::before {
									border: 1px solid white;
								}
							}
						}
					}
				}
			}
		}

		.melding {
			.container-holder {
				.column {
					height: 60px;
				}
			}
		}
	}
}

&.home {
	.header {
		.container-one-column {
			.container-holder {
				.column {
					.page-block.logo {
						position: absolute;
						top: 100px;
						left: 50%;
						right: 0;
						width: 297px;
						transform: translateX(-50%);

						img {
							max-height: 100%;
						}

						@include media-breakpoint-between(sm, md) {
							left: 160px;
						}
						@include media-breakpoint-up(md) {
							left: 50% !important;
						}
						@include media-breakpoint-up(xl) {
							width: 785px;
						}
					}
				}
			}
		}

		.melding {
			display: block;

			.container-holder {
				.column {
					background-color: #ca7b6a;

					.page-block {
						@extend .mx-auto;

						p {
							color: white;
							font-size: $font-size-base;
							padding: 8px 0;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

&.theme-demo {
	.header {
		.container-one-column {
			.container-holder {
				.column {
					background-color: $primary;
				}
			}
		}
	}
}

&:not(.home) {
	.header {
		.container-one-column {
			.container-holder {
				.column {
					.page-block.logo {
						max-width: 350px;
						margin-top: 235px;
						display: none;
					}
				}
			}
		}
	}
}

&.home {
	.header {
		.container-one-column {
			.container-holder {
				.column {
					.page-block.logo {
						@media(max-width: 991px) {
							display: none;
						}
					}
				}
			}
		}
	}
}
