.eyecatcher {
	min-height: 420px;
	@include media-breakpoint-up(md) {
		min-height: $carousel-min_height;
	}
	@include media-breakpoint-up(xl) {
		margin-top: -110px;
		transform: none;
	}

	&.large {
		min-height: 520px;
		@include media-breakpoint-up(sm) {
			min-height: $carousel-min_height--lg;
		}

		.page-block.logo {
			display: none;
		}

		.owl-carousel {
			min-height: 520px;
			@include media-breakpoint-up(sm) {
				min-height: $carousel-min_height--lg;
			}

			.owl-stage-outer {
				.owl-stage {
					.owl-item {
						.item {
							min-height: 520px;
							@include media-breakpoint-up(sm) {
								min-height: $carousel-min_height--lg;
							}
						}
					}
				}
			}
		}
	}

	.owl-carousel {
		min-height: 420px;
		@include media-breakpoint-up(sm) {
			min-height: $carousel-min_height;
		}

		.owl-stage-outer {
			position: relative;

			.owl-stage {
				.owl-item {
					.item {
						min-height: 600px;
						@include media-breakpoint-up(md) {
							min-height: $carousel-min_height;
						}

						.owl-caption {
							display: none;
						}
					}
				}
			}
		}

		.owl-nav, .owl-dots {
			display: none;
		}
	}
}

